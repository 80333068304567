<template>
<!-- 系统设置-账号 -->
    <div  class="communal system-account" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container fixed-style">
                <el-row >
                    <el-col :span="5" class="text-left">
                        <el-button type="success"  style="margin-left: 0;" @click="addAlter('新增账号','')" >新增账号</el-button>
                    </el-col>
                    <el-col :span="19">
                        <el-input  placeholder="请输入关键字查询" v-model="content"></el-input>
                        <el-button type="success" plain @click="serach" >搜索</el-button>
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div>
                    <template>
                            <el-table
                            :data="list"
                            style="width: 100%"
                            :height="contentHeight"
                            :row-class-name="tableRowClassName"
                            :empty-text="tableTip"
                            >
                            <el-table-column
                            prop="username"
                            label="姓名"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="sex"
                            label="性别"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="role_name"
                            label="角色"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="telphone"
                            label="联系方式"
                            width="160"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="addtime"
                            label="新增时间"
                            width="160"
                            >
                            </el-table-column>
                            <el-table-column
                            prop="status"
                            label="状态"
                            >
                            </el-table-column>
                            <el-table-column
                             label="操作" fixed="right">
                                <template slot-scope="scope" >
                                    <el-button type="text" @click="addAlter('账号信息',scope.row.id)">编辑</el-button>
                                    <el-button type="text" @click.stop="operation(scope.row.id,'冻结')" v-if="scope.row.status=='正常'">冻结</el-button>
                                    <el-button type="text" @click.stop="operation(scope.row.id,'解冻')" v-if="scope.row.status=='已冻结'">解冻</el-button>
                                </template>
                            </el-table-column>
                            </el-table>
                    </template>
                </div>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10,50,100,200]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
        <!-- 新增账号 start -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="480px"
            top="20%"
            >
            <div style="padding:30px 60px 0">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                    <el-form-item label="姓名" prop="username">
                        <el-input v-model="ruleForm.username" maxlength="5" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="ruleForm.sex">
                            <el-radio label="1">男</el-radio>
                            <el-radio label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="telphone">
                        <el-input v-model="ruleForm.telphone"  maxlength="5" type='number' placeholder="请输入11位联系电话，用于登录"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idCard">
                        <el-input v-model="ruleForm.idCard"  maxlength="18"  placeholder="请输入身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="角色" prop="role" >
                        <el-select v-model="ruleForm.role" placeholder="请选择角色" class="el-long"> 
                            <el-option :label="val.role_name" :value="val.role_id" v-for="(val,index) in roleList" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="登录密码" prop="password">
                        <!-- 录入明文，查看隐藏   -->
                        <el-input v-model="ruleForm.password" :type="pwdType"  placeholder="请设置登录密码" @focus="pwdFocus"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix">
                <div><el-button @click="dialogVisible = false">取 消</el-button></div>
                 <div><el-button type="primary"  @click="submitForm('ruleForm')">确 定</el-button></div>
            </div>
        </el-dialog>
        <!-- 新增账号 end -->
         <operationBtn
            v-if="showHide"
            :issue="issue"
            :hintTxt="hintTxt"
            :showHide="showHide"
            :operationId="operationId"
            :operationPsotUrl="operationPsotUrl"
            :operationPsotName="operationPsotName"
            @childByOperationBtn="childByOperationBtn"
        ></operationBtn>
    </div>
</template>
<script>
import operationBtn from "../../components/operationBtn";
import { AccountList } from "../../api/api";
import { StartAccountUser } from "../../api/api"; //启用
import { DelAccountUser } from "../../api/api"; //冻结
import { AddAccount } from "../../api/api"; //新增
import { EditAccountUser } from "../../api/api"; //修改
import { AccountUserInfo } from "../../api/api"; //详情
import { RoleUserLists } from "../../api/api"; //角色

export default {
  components: {
    operationBtn
  },
  data() {
    var checkName = (rule, value, callback, text) => {
      this.common.Validate.checkName(rule, value, callback, "姓名");
    };
    var checkTelphone = (rule, value, callback, text) => {
      this.common.Validate.checkTelphone(rule, value, callback, "手机号码");
    };
    var checkPwd = (rule, value, callback, text) => {
      this.common.Validate.checkPwd(rule, value, callback, "登录密码");
    };
    var checkIdCard = (rule, value, callback, text) => {
      this.common.Validate.checkIDNumber(rule, value, callback, "身份证号");
    };

    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [], //数据
      content: "", //搜索的内容
      roleList: "", //角色列表
      dialogVisible: false,
      dialogTitle: "",
      ruleForm: {
        username: "", //姓名
        sex: "", //性别
        telphone: "", //联系电话
        idCard: "", //身份证号
        password: "", //登录密码
        role: "" //角色
      },
      rules: {
        username: [{ required: true, trigger: "change", validator: checkName }],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        telphone: [
          { required: true, trigger: "change", validator: checkTelphone }
        ],
        idCard: [{ required: true, trigger: "change", validator: checkIdCard }],
        password: [{ required: true, trigger: "change", validator: checkPwd }],
        role: [{ required: true, message: "请选择角色", trigger: "change" }]
      },
      id: "", //保存点击行的账号id
      tableTip: "系统正在检索中…",
      // 操作性按钮弹框值   start
      issue: "", //问题   上面的文字
      hintTxt: "", //提示文本   下方的文字
      showHide: false, //弹框的隐藏显示
      operationId: "", //操作id
      operationPsotUrl: "", //操作接口名字
      operationPsotName: "", //请求参数名字
      // 操作性按钮弹框值   end
      pwdType: "" //密码框显示方式
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
    that.getRoleUserLists();
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      //搜索
      this.getList("搜索");
    },
    getList(operationName) {
      //账号列表   operationName判断是列表还是点击了搜索
      var that = this;
      var db = {
        pages: this.pages,
        pnums: this.psize,
        name: this.content //搜索内容
      };
      this.$axios.post(AccountList, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无账号记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无账号记录";
          }
        },
        err => {
          this.list = [];
          this.tableTip = "暂无账号记录";
        }
      );
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status == "已冻结") {
        return "freeze";
      }
    },
    getRoleUserLists() {
      //获取账号列表
      var that = this;
      var db = {};
      this.$axios.post(RoleUserLists, db).then(
        res => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.roleList = res.data.data;
            } else {
              this.roleList = [];
            }
          } else {
            this.roleList = [];
          }
        },
        err => {
          this.roleList = [];
        }
      );
    },
    submitForm(formName) {
      //新建账号
      this.$refs[formName].validate(valid => {
        if (valid) {
          var postUrl;
          var db;
          if (this.dialogTitle == "账号信息") {
            //修改
            postUrl = EditAccountUser;
            db = {
              username: this.ruleForm.username, //	姓名
              sex: this.ruleForm.sex, //	性别 1男 2女
              telphone: this.ruleForm.telphone, //	手机号
              card: this.ruleForm.idCard, //	身份证号
              password: this.ruleForm.password, //	密码
              role_id: this.ruleForm.role, //	角色id
              id: this.id
            };
          } else {
            //新增
            postUrl = AddAccount;
            db = {
              username: this.ruleForm.username, //	姓名
              sex: this.ruleForm.sex, //	性别 1男 2女
              telphone: this.ruleForm.telphone, //	手机号
              card: this.ruleForm.idCard, //	身份证号
              password: this.ruleForm.password, //	密码
              role_id: this.ruleForm.role //	角色id
            };
          }
          this.$axios.post(postUrl, db).then(
            res => {
              if (res.data.code == 1) {
                this.getList();
              } else {
                this.$message.error(res.data.msg);
              }
              this.dialogVisible = false;
              this.clearForm();
            },
            err => {
              this.$message.error(err.msg);
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addAlter(btnName, id) {
      //查看,修改   新增账号
      this.clearForm();
      this.dialogTitle = btnName;
      this.id = id;
      if (btnName == "账号信息") {
        this.pwdType = "password";
        //账号详情
        var db = {
          id: id //账号id
        };
        this.$axios.post(AccountUserInfo, db).then(
          res => {
            if (res.data.code == 1) {
              this.ruleForm.username = res.data.data.username; //账号名称
              this.ruleForm.telphone = res.data.data.telphone; //手机号码
              this.ruleForm.password = res.data.data.password; //登录密码
              this.ruleForm.role = res.data.data.role_id; //角色
              this.ruleForm.idCard = res.data.data.card; //身份证号
              this.ruleForm.sex = res.data.data.sex.toString(); //性别
              this.dialogVisible = true;
            } else {
              this.$message.error(res.data.msg);
            }
          },
          err => {
            this.$message.error(err.msg);
          }
        );
      } else {
        this.pwdType = "input";
        this.dialogVisible = true;
      }
    },
    operation(operationId, operationName) {
      this.operationPsotName = "id";
      this.operationId = operationId; //修改组件id
      if (operationName == "冻结") {
        this.issue = "确定冻结该账号？";
        this.hintTxt = "冻结后该账号将无法在本平台登陆";
        this.operationPsotUrl = DelAccountUser;
      } else {
        this.issue = "确定解冻该账号？";
        this.hintTxt = "解冻后该账号将恢复在本平台的相关权限";
        this.operationPsotUrl = StartAccountUser;
      }
      this.showHide = true; //显示组件
    },
    childByOperationBtn: function(showHide, dataCode) {
      this.showHide = showHide;
      if (dataCode == 1) {
        this.getList();
      }
    },
    clearForm() {
      if (this.$refs["ruleForm"] != undefined) {
        this.$refs["ruleForm"].resetFields();
      }
    },
    pwdFocus() {
      //修改密码清空
      if (this.pwdType == "password") {
        this.ruleForm.password = "";
      }
    }
  }
};
</script>

<style lang="scss">
.system-account {
  .login-hint {
    line-height: 40px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    padding-left: 6px;
    i {
      padding-right: 6px;
      font-size: 14px;
    }
  }
}
</style>
